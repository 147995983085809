<template lang="pug">
img(v-bind='image')
</template>

<script setup lang="ts">
const {
  src,
  height,
  width,
  alt = '',
  isLazy = false,
} = defineProps<{
  src: string
  height: number
  width: number
  alt?: string
  isLazy?: boolean
}>()

const image = computed(() => {
  const obj = {
    src,
    alt,
    width,
    height,
    class: '',
    loading: 'eager',
  }

  if (isLazy) obj.loading = 'lazy'

  return obj
})
</script>
