<template lang="pug">
div(class='overflow-hidden leading-5 my-3')
  p(v-for='file in files' :key='file.filename')
    template(v-if='file.key')
      img(src='/images/file-img.svg' alt='image' width='16' height='16' class='float-left mr-3' loading='lazy')
      a(:href='getImageUrl(file.key, file.filename)' target='_blank')
        InlineHtml(v-if='file.title' :text='file.title')
    template(v-else-if='file.folder')
      img(src='/images/file-pdf.svg' alt='pdf' width='16' height='16' class='float-left mr-3' loading='lazy')
      a(:href='getFileUrl(file.folder, file.filename)' target='_blank')
        InlineHtml(v-if='file.title' :text='file.title')
</template>

<script setup lang="ts">
const { getImageUrl } = useUrls()
const { files } = defineProps<{ files: TabContent['files'] }>()
</script>
