<template lang="pug">
#tabs(ref='container' class='mt-3')
  div(class='sticky top-0 z-50 overflow-hidden h-16')
    #tabHeader(
      ref='header'
      :class='{ "border-0 shadow-[0_3px_10px_0_rgba(153,153,153,0.75)] box-border": isSticky }'
      class='tabs-header bg-gray-lighter border-none'
    )
      ul(class='list flex')
        li(:class='isSticky ? "inline-block" : "hidden"' class='p-3 mr-3 cursor-pointer text-center' @click='selectTab("Top")')
          div(class='text-sm text-dark uppercase font-bold xs:text-base hover:no-underline') Top
        li(
          v-for='tab in tabsArray'
          :id='`tab-${tab.id}`'
          :key='tab.id'
          class='p-3 mr-3 cursor-pointer text-center'
          @click='selectTab(tab.id)'
        )
          div(class='text-sm text-dark uppercase font-bold xs:text-base hover:no-underline') {{ tab.name }}
      div(class='relative overflow-hidden h-1 bg-gray-lighter')
        div(ref='slide' class='slider absolute bottom-0 bg-primary h-1 border-none transition-all duration-300 ease-in')

  div(class='content pt-4 pb-6')
    ProductTabsTab(
      v-for='(tab, index) in tabsArray'
      :id='tab.id'
      :key='tab.id'
      :name='tab.name'
      :selected='alwaysShowTabs || index === getSelectedTabIndex'
      :class='tab.class'
    )
      slot(:name='tab.id')
</template>

<script setup lang="ts">
const { $scrollTo } = useNuxtApp()
const { tabsArray } = defineProps<{
  tabsArray: {
    name: string
    id: string
    class?: string
  }[]
}>()

const container = ref<HTMLElement | null>(null)
const header = ref<HTMLElement | null>(null)
const slide = ref<HTMLElement | null>(null)
const getSelectedTabIndex = ref(0)
const alwaysShowTabs = ref(true)
const supressDetect = ref(false)
const scrollPosTopPadding = ref(50)
const isSticky = ref(false)

onMounted(() => {
  nextTick(() => {
    slideMarker('description')
    window.addEventListener('scroll', handleScroll)
  })
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

function slideMarker(tabId: string) {
  if (!slide.value) return

  const tab = document.getElementById(`tab-${tabId}`)
  if (!tab) return
  slide.value.style.width = tab.offsetWidth + 'px'
  slide.value.style.left = tab.offsetLeft + 'px'
}

// Central catch for scroll events
function handleScroll() {
  // Disabled when we know where we are going
  if (supressDetect.value) return
  detectStickHeader()
  detectSection()
}

function selectTab(tabId: string, thenScroll = true) {
  // Scroll to Top
  if (tabId === 'Top') {
    $scrollTo('#top', 500, {
      onStart: () => {
        // We know where to go so disable Detect methods
        supressDetect.value = true
        // We know the header needs to be Fixed now so just do it
        // When the header is Fixed it auto slides to the first tab so we don't need to worry about that
        isSticky.value = false
        stickHeader(false)
      },
      onCancel: () => {
        // Turn detection back on if they interrupt the auto scroll
        supressDetect.value = false
      },
      onDone: () => {
        // Turn detection back on when we are done
        supressDetect.value = false
      },
    })
    return
  }
  // Scroll to the section selected by default
  // We don't want to scroll if we are Detecting the tab based on user scroll
  if (typeof thenScroll === 'undefined') thenScroll = true
  if (thenScroll) scrollToSection(tabId)
  else slideMarker(tabId)
}

// Fix the tab header when user scrolls past it
function detectStickHeader() {
  if (!container.value) return
  // It would be super cool if we could cache this and update anytime the dom changes...
  const sticky = container.value.getBoundingClientRect().top + document.documentElement.scrollTop
  // Determine if the user is beyond the header or not
  if (window.scrollY >= sticky) {
    isSticky.value = true
    stickHeader(true)
  } else if (header.value?.classList.contains('-fixed')) {
    isSticky.value = false
    stickHeader(false)
  }
}

// Detect if the user has scrolled into a new tab section then move the underline marker
function detectSection() {
  tabsArray.forEach((tab) => {
    const el = document.getElementById(`scrollTarget-${tab.id}`)
    if (!el) return
    const top = el.getBoundingClientRect().top + window.scrollY - scrollPosTopPadding.value
    if (window.scrollY > top && window.scrollY < top + el.offsetHeight) selectTab(tab.id, false)
  })
}
// Controls the Fix/UnFix of tab header
function stickHeader(isSticky: boolean) {
  if (!header.value) return

  if (isSticky) {
    // Fix the header
    header.value?.classList.add('-fixed')
    // Set the width of the header to fill the container
    header.value.style.width = container.value?.offsetWidth + 'px'
    return
  }

  // Revert the header to a docked state
  header.value?.classList.remove('-fixed')
  header.value.style.width = 'auto'
  // Guarantee that if we ever doc the bar the slider goes back to home
  // We need to do this on next tick because dynamic classes are not updated before this runs
  nextTick(() => {
    slideMarker('description')
  })
}

// Scroll down to a section when a user clicks a corresponding tab
function scrollToSection(tabId: string) {
  nextTick(() => {
    $scrollTo(`#scrollTarget-${tabId}`, 500, {
      offset: -Math.abs(scrollPosTopPadding.value),
      onStart: () => {
        // Don't bother with Detect methods on scroll, save the effort since we know what needs to happen.
        supressDetect.value = true
        // We know they will scroll passed the header so just fix it.
        isSticky.value = true
        stickHeader(true)
        // We know where the marker needs to be and want it to move while we scroll.
        // We need to do this on next tick because dynamic classes are not updated before this runs
        nextTick(() => {
          slideMarker(tabId)
        })
      },
      onCancel: () => {
        // Turn detection back on if they interrupt the auto scroll
        supressDetect.value = false
      },
      onDone: () => {
        // Turn detection back on when we are done
        supressDetect.value = false
      },
    })
  })
}
</script>
