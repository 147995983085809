export const useProductStore = defineStore('Product', () => {
  const data: Ref<ProductLine | null> = ref(null)
  const { $timing } = useNuxtApp()

  const { getApiUrl } = useUrls()

  async function init(productLineSlug: string, skuSlug?: string) {
    let slug = productLineSlug
    if (skuSlug) slug += `/${skuSlug}`

    if (data.value && data.value.refreshKey === slug) return

    const url = getApiUrl('product') + slug
    $timing.start('product-api')
    const resp = (await $fetch(url)) as ProductLine
    $timing.end('product-api')

    data.value = { ...resp, refreshKey: slug }
  }

  return { data, init }
})

declare global {
  interface Product {
    brand: {
      name: string
    }
    refreshKey: string
    mpn?: string
    upc?: string
    sku?: string
    skuSlug?: string
    hasOverriddenFeatureBullets?: boolean
    hasOverriddenInstallGuide?: boolean
    isDealerOnly?: boolean
    orderBullets?: string[]
    attributes?: { key: string; value: string }[]
    guaranteedFit?: 'SITE' | 'PHONE'
    applications?: {
      year: string
      make: string
      model: string
      notes?: string
    }[]
  }
  interface ProductLine extends Product {
    promo?: {
      title: string
      content: string
      file?: AWSFile
    }
    isPreOrder?: boolean
    isMadeToOrder: boolean
    preOrderDate?: string
    promoImages?: [
      {
        image: AWSFile
        url?: string
      }
    ]
    discount?: {
      amountFormatted: string
      expiresFormatted: string
    }
    additionalFeatures?: ImageBullet[]
    averageRating: number
    actualPrice?: MinMax
    basePrice?: MinMax
    crumbs: Crumb[]
    description: {
      main: string
      extended?: string
      // more?
    }
    featureVideo: Video
    features?: TabContent
    featureBlocks?: {
      title: string
      content: string
      image: AWSFile
    }[]
    faq?: TabContent
    gallery: GalleryImage[]
    googleProductId: string
    guarantee: {
      title: string
      items: GuaranteeItem[]
    }
    hasMAPViolation?: boolean
    howItWorks?: ImageBullet[]
    image: {
      title: string
      key: string
      filename: string
    }
    installation: TabContent
    isDisabled: boolean
    isMadeInAmerica: boolean
    // inTheBox: string[] -- no longer exported by the BE (for like, years)
    isNew: boolean
    isOutOfStock: boolean
    name: string
    orderNotes: string
    productLineSlug: string
    qtySelector: {
      default: number
      enabled: boolean
    }
    shipping: {
      estimatedDeliveryDate?: {
        min: string
        max: string
      }
      isEligibleFreeShipping: boolean
      isFreight: boolean
      sameDay: boolean
      notes?: string
    }
    specSheet: SpecSheetItem[]
    specifications?: TabContent
    totalReviews: number
    videos: TabContent
    warranty: TabContent
    yotpoId: string
  }

  interface MinMax {
    min: number
    max: number
  }
  interface Crumb {
    title: string
    urlData: {
      name: string
      params?: {
        lvl0: string
        lvl1?: string
        lvl2?: string
        lvl3?: string
        productLine?: string
        skuSlug?: string
      }
    }
  }
  interface SpecSheetItem {
    key: string
    value: string
  }

  interface ImageBullet {
    image: AWSFile
    content?: string
    title: string
  }

  interface AWSFile {
    filename: string
    folder?: string // for files
    title?: string
    key?: string // for images
  }

  interface GalleryImage {
    filename: string
    key: string
  }

  interface GuaranteeItem {
    graphicType: string //TODO Enum?
    text: string
    toolTip: string
  }
  interface TabContent {
    title?: string // warranty
    content: string
    files?: AWSFile[]
    videos?: Video[]
  }

  interface Video {
    duration?: number
    id: string
    title?: string
    type?: string // TODO enum? WISTIA/YOUTUBE
    uploadDate?: string
    thumbnail?: AWSFile
  }
}
