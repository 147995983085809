<template lang="pug">
div(class='image-list flex flex-wrap -m-3 mb-3')
  div(
    v-for='(imageBullet, index) in data'
    :key='imageBullet.image.key'
    class='p-3 odd:pr-1 even:pl-1 md:odd:pr-3 md:even:pl-3 md:px-3'
    :class='layoutModeCSS'
  )
    div(class='pb-3' :class='{ "w-full": mode === "blocks" }')
      img(
        v-if='imageBullet.image.key'
        :key='imageBullet.image.key'
        width='440'
        height='308'
        :src='getImageUrl(imageBullet.image.key, imageBullet.image.filename, 440, 308)'
        :alt='imageBullet.image.title || ""'
        class='border cursor-pointer'
        loading='lazy'
        @click='loadLightbox(index)'
      )
    div(class='content')
      div(v-if='imageBullet.title' class='font-bold uppercase pb-2')
        InlineHtml(:text='imageBullet.title')
      div(v-if='imageBullet.content' class='text-sm')
        InlineHtml(:text='imageBullet.content')
</template>
<script setup lang="ts">
const { getImageUrl } = useUrls()
const { showLightbox } = usePhotoswipeLightbox()
const {
  data,
  mode = 'bullets',
  thirds = false,
} = defineProps<{
  data: ImageBullet[]
  mode?: 'bullets' | 'blocks'
  thirds?: boolean
}>()

const layoutModeCSS = computed(() => {
  // Already commented out from Nuxt 2 .. return [`-${this.mode}`, this.thirds === true && this.mode === 'blocks' ? '-thirds' : '']
  let columnCSS = ''
  if (thirds === true && mode === 'blocks') {
    columnCSS = 'w-1/2 md:w-1/3'
  } else if (mode === 'blocks') {
    columnCSS = 'w-1/2'
  } else {
    columnCSS = 'is-12'
  }
  return columnCSS
})

function loadLightbox(index: number) {
  const items: PhotoswipeItem[] = []
  data.forEach((bullet) => {
    if (!bullet.image.key) return
    items.push({
      src: getImageUrl(bullet.image.key, bullet.image.filename, 1024, 768) || '',
      width: 1024,
      height: 768,
      caption: bullet.title,
    })
  })
  showLightbox(items, index)
}
</script>
