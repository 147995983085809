<template lang="pug">
div
  div(class='content')
    h2 Description
    slot(name='description')
  div
    template(v-for='tab in tabsArray')
      a(
        v-if='tab.id !== "description"'
        :key='tab.id'
        class='flex text-gray-darker border bg-gray-lightest p-3 my-3 capitalize rounded hover:no-underline md:mx-3'
        @click='$overlay.open(tab.id)'
      ) {{ tab.name }}
        div(class='arrow-right ml-auto self-center')
  div
    template(v-for='tab in tabsArray')
      Overlay(
        v-if='tab.id !== "description"'
        :key='tab.id'
        :unmount='false'
        :unique-key='tab.id'
        slide-direction='right'
        mode='LIGHT'
        :title='tab.name'
      )
        slot(:name='tab.id')
</template>

<script setup lang="ts">
const { $overlay } = useNuxtApp()
const { tabsArray } = defineProps<{
  tabsArray: {
    id: string
    name: string
  }[]
}>()
</script>
