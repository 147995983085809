<template lang="pug">
div
  div(class='lg:flex lg:-m-3')
    div(
      v-if='data.featureVideo || data.featureBlocks || (data.features && data.features.content) || data.description'
      class='lg:flex-1 lg:m-3'
    )
      div(v-if='data.featureVideo' ref='featureVideo' class='my-3')
        VideoPlayer(:video='data.featureVideo')
      div(v-if='data.featureBlocks')
        ProductExtraInfoImageList(
          :data='data.featureBlocks'
          mode='blocks'
          :thirds='!data.specSheet && !data.guarantee'
        )
      div(v-if='data.features && data.features.content && !data.hasOverriddenFeatureBullets')
        InlineHtml(:text='data.features.content')
      div(
        v-if='data.description'
        class='[&_table]:mb-[15px] [&_table]:mt-[15px] [&_table]:bg-white [&_table]:border [&_table]:border-black [&_table]:p-[6px] [&_th]:bg-[#eee] [&_th]:border [&_th]:border-black [&_th]:p-[6px] [&_td]:bg-white [&_td]:border [&_td]:border-black [&_td]:p-[6px]'
      )
        div(v-if='data.description.main')
          InlineHtml(:text='data.description.main')
        div(v-if='data.description.extended')
          InlineHtml(:text='data.description.extended')
    div(v-if='(data.specSheet && data.specSheet.length > 1) || data.guarantee' class='w-full lg:m-3 lg:w-1/3')
      ProductExtraInfoDescriptionSpecSheet(
        class='mt-3'
        :class='{ "lg:min-h-[355px] xl:min-h-[427px]": data.featureVideo }'
        :data='data'
      )
  div(class='lg:w-2/3')
    YotpoFocusedReview(v-if='isIntegrationEnabled("yotpo")' :yotpo-id='data.yotpoId')
  div(class='prop-65 relative inline-block font-bold mt-4 mb-3 group')
    | California Residents:&nbsp;
    span(class='font-normal underline cursor-pointer') Prop 65 Warning
    div(class='absolute left-0 right-0 bottom-full hidden group-hover:block')
      img(src='/images/prop65.svg' width='271' height='56' alt='May cause cancer' loading='lazy')
  div(v-if='data.orderNotes')
    h3 Order Notes
    InlineHtml(:text='data.orderNotes')
  div(v-if='data.shipping && data.shipping.notes')
    h3 Shipping Notes
    InlineHtml(:text='data.shipping.notes')
  div(v-if='data.features && (data.features.files || data.features.videos)')
    h3 More Information
    ProductExtraInfoFileList(v-if='data.features && data.features.files' :files='data.features.files')
    ProductExtraInfoVideoList(
      v-if='data.features && data.features.videos'
      :videos='data.features.videos'
      section='video-list'
    )
</template>

<script setup lang="ts">
const { isIntegrationEnabled } = useUtils()
const { data } = defineProps<{ data: ProductLine }>()
</script>
