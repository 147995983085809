<template lang="pug">
div(v-if='hasFitDrills')
  //-  We don't trigger initControls so we'll never have drills server side
  ProductVehicleSelectionFits(v-if='hasNoFitment' key='select-fitment' type='select')
  ProductVehicleSelectionFits(
    v-if='showFitsGood && ocStore.isGoodFitment && !showVehicleSelector && !ocStore.hasMismatchedProduct'
    key='good-fitment'
    type='good'
    :guaranteed-fit='guaranteedFit'
  )
  ProductVehicleSelectionFits(v-if='!ocStore.isGoodFitment && !showVehicleSelector' key='bad-fitment' type='bad')
  ProductVehicleSelectionFits(
    v-if='!hasFullFitment && ocStore.isGoodFitment && !hasNoFitment'
    key='more-fitment'
    type='more'
  )
  ProductVehicleSelectionFits(
    v-if='ocStore.hasMismatchedProduct && !showVehicleSelector && !hasNoFitment'
    key='mismatch-fitment'
    type='mismatch'
  )
  div(v-show='showVehicleSelector || (!showFitsGood && ocStore.isGoodFitment)' class='mb-0 md:mb-5')
    div(data-testid='drillSelection')
      ProductDrill(
        v-for='drillKey in ocStore.fitmentDrillKeys'
        :id='`scrollTarget-${drillKey}`'
        :key='drillKey'
        :type='drillKey'
      )
      a(@click='fitmentDisplayStore.showFitmentModal({ isFitsMode: true })') Don't see your vehicle listed?
</template>

<script setup lang="ts">
const ocStore = useOrderControlStore()
const fitmentDisplayStore = useFitmentDisplayStore()
const fitmentStore = useFitmentStore()
const routeBuilder = useRouteBuilder()
const route = useRoute()
const showVehicleSelector = ref(false)

const { guaranteedFit } = defineProps<{ guaranteedFit?: 'SITE' | 'PHONE' }>()

const hasNoFitment = computed(() => {
  return !fitmentStore.year
})

const hasFitDrills = computed(() => {
  return ocStore.fitmentDrillKeys.length > 0
})

const hasFullFitment = computed(() => {
  if (ocStore.fitmentDrillKeys.length === 0) return false

  const hasMissingFitment = ocStore.fitmentDrillKeys.some((drillKey) => {
    return !fitmentStore.isKey(drillKey) || !fitmentStore[drillKey]
  })

  return !hasMissingFitment
})

const lastFitmentDrillKey = computed(() => {
  const fitmentDrillCount = ocStore.fitmentDrillKeys.length

  if (fitmentDrillCount == 0) return undefined

  return ocStore.fitmentDrillKeys[fitmentDrillCount - 1]
})

const lastFitmentDrillSelectedValue = computed(() => {
  if (!lastFitmentDrillKey.value) return undefined
  return ocStore.getDrill(lastFitmentDrillKey.value)?.selectedValue
})

watch(lastFitmentDrillSelectedValue, (newVal) => {
  if (newVal === '-1' || newVal === undefined) return
  updateFitment()
  if (!route.params.skuSlug) updateUrl()
  showVehicleSelector.value = false
})

const showFitsGood = computed(() => {
  return !showVehicleSelector.value && hasFullFitment.value
})

const $pageBus: any = inject('pageBus')

onMounted(() => {
  $pageBus.$on('changeVehicle', () => {
    showVehicleSelector.value = true
  })
})
onBeforeUnmount(() => {
  $pageBus.$off('changeVehicle')
})

function updateFitment() {
  const newFitment: Fitment = {
    year: undefined,
    make: undefined,
    makeSlug: undefined,
    model: undefined,
    modelSlug: undefined,
  }

  ocStore.fitmentDrillKeys.forEach((key) => {
    if (!fitmentStore.isKey(key)) return

    const drill = ocStore.getDrill(key)
    if (!drill?.selectedValue || drill.selectedValue === '-1') return

    let selectedText = drill?.options.find((option) => option.slug == drill.selectedValue)?.text
    if (!selectedText || selectedText == 'N/A') selectedText = ''

    newFitment[key] = drill?.selectedValue

    const textKey = key.replace('Slug', '')
    if (fitmentStore.isKey(textKey) && textKey != 'year') newFitment[textKey] = selectedText
  })

  fitmentStore.update(newFitment)
  fitmentDisplayStore.setupFitment()
  fitmentDisplayStore.initSelects()
}

function updateUrl() {
  navigateTo(
    routeBuilder.formatRoute({
      name: 'product',
      params: { productLine: route.params.productLine },
      query: Object.assign({}, route.query),
    })
  )
}
</script>
