<template lang="pug">
Component(:is='is' v-if='data' ref='Tabs' v-bind='props')
  template(#description)
    ProductExtraInfoDescription(:data='data')
  template(#details)
    ProductExtraInfoDetails(:data='data')
  template(#videos)
    ProductExtraInfoVideoList(v-if='data.videos.videos' :videos='data.videos.videos' section='videos')
  template(#warranty)
    ProductExtraInfoWarranty(v-if='data.warranty' :data='data')
  template(#install)
    ProductExtraInfoInstallation(:data='data')
  template(#reviews)
    YotpoReviews(:yotpo-id='data.yotpoId' :yotpo-name='data.name')
  template(#qa)
    YotpoQuestions(:yotpo-id='data.yotpoId' :yotpo-name='data.name')
  template(#photos)
    YotpoCustomerPhotos(
      v-if='!!$sitewideConfig.config.yotpoProductGalleryId'
      :yotpo-id='data.yotpoId'
      :gallery-id='$sitewideConfig.config.yotpoProductGalleryId'
    )
</template>
<script setup lang="ts">
const { $device } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
const route = useRoute()
import { ProductExtraInfoPanels, ProductTabsScroller } from '#components'
const { hasCustomerPhotos, data } = defineProps<{
  hasCustomerPhotos: boolean
  data: ProductLine
}>()
const is = computed(() => {
  if ($device.value.isSmall) return ProductExtraInfoPanels

  return ProductTabsScroller
})

const props = computed(() => {
  /** (Nuxt 2) Important
   *
   * The order of this array also determines the order these tabs will appear.
   * 'name' must be the same as Slot='' above in the template and is also
   * what appears on screen.
   *
   */

  const array: TabObj[] = [{ name: 'description', id: 'description', class: '[&_>h2]:hidden' }]
  if (isProductPage && data.applications && data.applications.length > 0) array.push({ name: 'fits', id: 'fits' })
  if (data.howItWorks || data.additionalFeatures || data.faq || data.specifications)
    array.push({ name: 'details', id: 'details' })
  if (data.videos) array.push({ name: 'videos', id: 'videos' })
  if (data.installation) array.push({ name: 'install', id: 'install' })
  if (data.warranty) array.push({ name: 'warranty', id: 'warranty' })
  if (isIntegrationEnabled('yotpo') && data.yotpoId) {
    if (hasCustomerPhotos) array.push({ name: 'Customer Photos', id: 'photos' })
    array.push({ name: 'reviews', id: 'reviews' })
    array.push({ name: 'Q&A', id: 'qa' })
  }
  return { tabsArray: array }
})

const isProductPage = computed(() => route.params?.skuSlug !== undefined)

interface TabObj {
  id: string
  name: string
  class?: string
}
</script>
