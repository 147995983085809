<template lang="pug">
div
  BaseButton(class='w-full' :is-waiting='isLoading' @click='requestNotification') Notify me when available
</template>

<script setup lang="ts">
const { $cognito, $uiEvents } = useNuxtApp()
const rootStore = useRootStore()
const { isIntegrationEnabled } = useUtils()
const { getApiUrl } = useUrls()
const notifications = useNotifications()

const { productLineSlug = '', skuSlug = '' } = defineProps<{
  productLineSlug?: string
  skuSlug?: string
}>()

const isLoading = ref(false)

function requestNotification() {
  if (!isIntegrationEnabled('cognito')) return

  $cognito.openModal(signupForNotification)
}

async function signupForNotification() {
  if (isLoading.value) return

  isLoading.value = true

  try {
    // Get account email
    const userAttributes = await $cognito.getUserAttributes()
    const email = userAttributes?.email
    const permId = rootStore.permId || ''

    const url = getApiUrl('product') + `${productLineSlug}/${skuSlug}/backorder`
    await $fetch(url, {
      method: 'POST',
      body: {
        email,
      },
      headers: {
        'x-perm-id': permId,
      },
    })

    // tell user they were added
    notifications.addSuccess('Success', `You will be notified when the product is available!`)

    // emit event to segment
    $uiEvents.$emit('backInStockSubscribed', {
      skuSlug: skuSlug,
      email,
    })
  } catch (error) {
    notifications.addError('Error', 'Please try again later.')
  }

  isLoading.value = false
}
</script>
