<template lang="pug">
div(
  :data-product-id='yotpoId'
  :data-name='yotpoName'
  :data-url='dataURL'
  data-mode='questions'
  class='yotpo yotpo-main-widget overflow-hidden'
)
</template>
<script setup lang="ts">
const { $sitewideConfig } = useNuxtApp()
const { params } = useRoute()

useYotpo()
const { yotpoId, yotpoName } = defineProps<{
  yotpoId: string
  yotpoName: string
}>()
const dataURL = computed(() => {
  return 'https://' + $sitewideConfig.domain + '/p/' + params.productLine
})
</script>
