<template lang="pug">
div(
  v-if='ocStore.quickProduct'
  class='bg-warning-platform border-2 border-transparent rounded p-3 text-center mb-5'
  :class='{ "animate-blink border-warning-dark": props.shouldFlash }'
)
  div(class='flex justify-center items-center text-dark text-lg font-bold mb-1')
    ErrorIcon(class='mr-1 text-warning-dark fill-current')
    | Fitment Notice
  div(class='text-dark')
    p(class='mb-1')
      | The part you are viewing does not fit your vehicle! We found this part ({{ ocStore.quickProduct.mpn || ocStore.quickProduct.sku }}) that matches.
      br
      br
      BaseButton(@click='go') View Matching Part
</template>

<script setup lang="ts">
import ErrorIcon from '@/assets/errorIcon.svg?component'
const route = useRoute()
const ocStore = useOrderControlStore()
const props = defineProps<{
  shouldFlash: boolean
}>()

function go() {
  if (!route.name) return

  navigateTo({
    name: route.name,
    params: {
      productLine: route.params.productLine,
      skuSlug: ocStore.quickProduct?.skuSlug,
    },
  })
}
/*

    mounted() {
        this.$pageBus.$on('blinkMismatched', () => {
            this.blinkIt = false
            setTimeout(() => {
                this.blinkIt = true
            }, 0)
        })
    },
    beforeDestroy() {
        this.$pageBus.$off('blinkMismatched')
    },
    methods: {

    },
}*/
</script>
