<template lang="pug">
BaseForm(v-slot='{ value }' @submit='addToCart')
  ProductSmallGroup(
    v-for='(groupKey, index) in ocStore.groupKeys'
    :key='groupKey'
    :group-key='groupKey'
    :array-index='index'
    :qty-selector='data.qtySelector'
  )

  BaseButton(
    v-if='!$sitewideConfig.config.nonTransactionalEnabled'
    type='submit'
    size='MEDIUM'
    :is-disabled='isDisabled(value)'
    :is-waiting='wait'
    class='h-full w-full'
  ) Add To Cart
</template>
<script setup lang="ts">
const ocStore = useOrderControlStore()
const cartStore = useCartStore()
const fitmentStore = useFitmentStore()
const notifications = useNotifications()
const { $overlay, $uiEvents, $sitewideConfig } = useNuxtApp()
const wait = ref(false)
const { data } = defineProps<{ data: ProductLine }>()

function isDisabled(value) {
  // Return false if any value is true
  return !Object.values(value).some((v) => v === true)
}

async function addToCart(formData) {
  // nonTransactionalEnabled shouldn't matter but... just in case
  if (wait.value || $sitewideConfig.config.nonTransactionalEnabled) return
  wait.value = true

  const items = []
  Object.entries(formData).forEach(([key, value]) => {
    // Only add items that are checked
    if (value === true) {
      items.push({
        productId: key,
        qty: 1,
        fitmentData: fitmentStore.$state,
      })
    }
  })

  try {
    await cartStore.addItem(items)
  } catch (error) {
    wait.value = false
    notifications.addError('Error', 'Unable to add item to cart. Please try again later.')
    return
  }

  // Tell Segment what we just added
  $uiEvents.$emit('addGroupsToCart', items)

  wait.value = false

  $overlay.open('mc')
}
</script>
