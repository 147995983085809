<template lang="pug">
div(v-if='data' class='text-center overflow-hidden')
  template(v-for='promoImage in data.promoImages' :key='promoImage.image.filename')
    a(v-if='promoImage.url' :href='promoImage.url' :target='urlTarget(promoImage.url)' aria-label='Promo Link')
      img(
        :src='getImageUrl(promoImage.image.key, promoImage.image.filename)'
        alt=''
        width='523'
        height='125'
        loading='lazy'
      )
    img(
      v-else
      :src='getImageUrl(promoImage.image.key, promoImage.image.filename)'
      alt=''
      width='523'
      height='125'
      loading='lazy'
    )
</template>

<script setup lang="ts">
const { data } = useProductStore()
const { getImageUrl } = useUrls()

function urlTarget(url: string) {
  // Open PDF documents in new windows
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, ext] = url.split('.')
  return ext === 'pdf' ? '_blank' : '_self'
}
</script>
