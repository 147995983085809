<template lang="pug">
div(class='vehicle-fit flex items-center pb-3' :class='classObject')
  div
    div(class='fitIcon relative rounded-full p-5 w-8 h-8 md:p-3')
      XIcon(v-if='type === "bad"' class='icon absolute m-auto inset-0 fill-current text-white')
      ExclamationPoint(v-else-if='type === "mismatch"' class='icon absolute m-auto inset-0 fill-current text-white')
      PhoneIcon(
        v-else-if='type === "good" && $sitewideConfig.config.guaranteedFitEnabled && guaranteedFit === "PHONE"'
        alt='phone'
        class='icon absolute m-auto inset-0 w-5 fill-white'
      )
      img(v-else-if='type === "good"' src='/images/checkmarkThick.svg' alt='checkmark' class='icon absolute m-auto inset-0')
      QuestionMark(
        v-else-if='type === "more" || type === "select"'
        class='icon absolute m-auto inset-0 fill-current text-white'
        style='width: 10px'
      )

  div(class='pl-4 w-full')
    div
      div(class='font-bold text-dark')
        div(v-if='type === "select" || type === "more"')
          span(class='block font-bold') Enter Fitment
          span(class='font-normal') See if this product fits your vehicle
        span(v-if='type === "bad" || type === "mismatch"') Does Not Fit Your Vehicle

        FitmentGuaranteedFit(
          v-if='type === "good" && $sitewideConfig.config.guaranteedFitEnabled && guaranteedFit'
          :type='guaranteedFit'
        )
        span(v-else-if='type === "good"') Fits Your Vehicle

        div(v-if='type === "bad" || type === "good"' class='text-gray leading-4' data-testid='filteredFitment')
          span {{ fitmentStore.fitmentFormatted }}
          span(v-if='fitmentStore.fitmentFormattedSecondary') , {{ fitmentStore.fitmentFormattedSecondary }}

        UniversalLink(v-if='type === "bad"' :link='mmyParams' class='font-normal py-2 inline-block') See products that fit your vehicle
      div(v-if='type !== "more" && type !== "select"' class='text-sm leading-4 mt-1 md:mt-0')
        a(class='inline-block underline text-dark md:self-center md:text-right' @click='$pageBus.$emit("changeVehicle")') Change Vehicle
</template>

<script setup lang="ts">
import XIcon from '@/assets/x.svg?component'
import QuestionMark from '@/assets/questionMark.svg?component'
import ExclamationPoint from '@/assets/exclamationPoint.svg?component'
import PhoneIcon from '@/assets/phone.svg?component'

const fitmentStore = useFitmentStore()
const { $uiEvents, $sitewideConfig } = useNuxtApp()
const route = useRoute()
const { type = 'good', guaranteedFit } = defineProps<{
  type: 'good' | 'bad' | 'more' | 'mismatch' | 'select'
  guaranteedFit?: 'SITE' | 'PHONE'
}>()

const $pageBus: any = inject('pageBus')

const classObject = computed(() => {
  return {
    '-fitsGood [&_.fitIcon]:bg-success [&_.icon]:w-5': type === 'good',
    '-fitsBad [&_.fitIcon]:bg-danger [&_.icon]:w-4': type === 'bad',
    '-fitsMore [&_.fitIcon]:bg-gray-dark [&_.icon]:w-4': type === 'more',
    '-fitsMismatch [&_.fitIcon]:bg-warning-dark [&_.icon]:h-5 [&_.icon]:left-[2px]': type === 'mismatch',
    '-fitsSelect [&_.fitIcon]:bg-gray-dark [&_.icon]:w-4': type === 'select',
  }
})

const mmyParams = computed(() => {
  const mmy = ['', 'v']
  if (fitmentStore.makeSlug) mmy.push(fitmentStore.makeSlug)
  if (fitmentStore.modelSlug) mmy.push(fitmentStore.modelSlug)
  if (fitmentStore.year) mmy.push(fitmentStore.year)
  return mmy.join('/')
})

onMounted(() => {
  if (type === 'bad') $uiEvents.$emit('doesNotFitViewed', route.fullPath)
})
</script>
