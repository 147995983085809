<template lang="pug">
div(v-if='showDealerLocatorLink')
  template(v-if='mode === "dealer"')
    template(v-if='shouldRender')
      template(v-if='localDealer')
        div(class='p-4 border-y leading-none')
          p(class='mb-4 font-bold') Shop at your local dealer

          div(class='space-y-2 mb-4')
            p(class='font-bold') {{ localDealer.name }}
            div(v-if='localDealer.isPreferred' class='flex items-center gap-x-1')
              img(src='/images/preferredDealerIcon.svg' width='16' height='16' alt='Preferred Dealer')
              span(class='text-danger-dark font-bold') Preferred Dealer

            a(
              v-if='dealerPhone'
              class='text-inherit inline-block font-bold'
              :href='`tel:${dealerPhone}`'
              @click='emitDealerPhoneEvent'
            ) {{ dealerPhone }}

          NuxtLink(:to='dealerRoute')
            span(@click='emitDealerLinkEvent') More Info

      template(v-else)
        div(class='flex items-center gap-x-4 leading-tight')
          img(src='/images/pin-icon.svg' width='27' height='32' alt='Find A Dealer')

          NuxtLink(:to='dealerRoute' class='font-bold')
            span(@click='emitDealerLinkEvent') Find A Local Dealer

  template(v-else)
    BaseButton(:to='dealerRoute' size='MEDIUM' class='w-full' @click='emitDealerLinkEvent') Find A Local Dealer
</template>

<script setup lang="ts">
const { mode = 'button', data } = defineProps<{
  mode?: 'dealer' | 'button'
  data: Product
}>()

const { $sitewideConfig, $uiEvents, $geolocation, $algolia } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()

const shouldRender = ref(false)
const localDealer = ref<any>(null)

const dealerRoute = computed(() => {
  let path = '/dealer-locator/'

  if ($sitewideConfig.config.dealerLocatorShowBrandFacet && data.brand) path += `?brand=${data.brand.name}`

  return path
})

const showDealerLocatorLink = computed(() => {
  // Should we show the link?
  const isFeatureEnabled =
    $sitewideConfig.config.dealerLocatorEnabled && $sitewideConfig.config.dealerLocatorShowProductPageLink

  // If the madeByRT stuff is enabled then include that in the decision to show the link.
  if ($sitewideConfig.config.madeByRealTruckEnabled)
    return isFeatureEnabled && $sitewideConfig.config.madeByRealTruckBrands?.data.includes(data.brand?.name)

  return isFeatureEnabled
})

const dealerPhone = computed(() => {
  if (!localDealer.value?.phone) return

  return formatPhoneDash(formatPhoneE164(localDealer.value.phone))
})

watch(
  () => $geolocation.latitude,
  () => {
    getLocalDealer()
  }
)

onMounted(async () => {
  if (mode !== 'dealer' || !isIntegrationEnabled('geolocation')) return

  await getLocalDealer()

  // At this point we have enough info to show you the local dealer
  // or the default experience
  shouldRender.value = true
})

async function getLocalDealer() {
  if (!$geolocation.latitude || !$geolocation.longitude) return

  const lat = $geolocation.latitude
  const lng = $geolocation.longitude

  $algolia.searchContexts.dealerLocator.setRadius(25)
  $algolia.searchContexts.dealerLocator.setLocation(lat ?? 0, lng ?? 0)

  $algolia.searchContexts.dealerLocator.indexes.forEach((index) => {
    index.clearRefinements()

    index.addRefinements('brands.brand', data.brand.name)

    let preferredBrandsFacet = 'preferredBrands'
    if (index.key === 'brand') preferredBrandsFacet = `NOT ${preferredBrandsFacet}`
    index.addRefinements(preferredBrandsFacet, data.brand.name)
  })

  const dealerSearchResponse = await $algolia.searchContexts.dealerLocator._search()

  // search response should be an array
  if (!dealerSearchResponse || !Array.isArray(dealerSearchResponse)) return

  localDealer.value = dealerSearchResponse.flatMap(({ results }) => results)[0]
}

function emitDealerLinkEvent(event: any) {
  $uiEvents.$emit('dealerLocatorProductLinkClicked', {
    product: data,
    linkText: event.target?.innerText,
    clickUrl: event.target?.pathname,
  })
}

function emitDealerPhoneEvent() {
  $uiEvents.$emit('dealerLocatorPhoneClicked', {
    dealer: localDealer.value,
  })
}
</script>
