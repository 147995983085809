<template lang="pug">
div(v-if='data' class='w-full space-y-8')
  div(v-if='data.promo' class='relative w-full')
    strong(v-if='data.promo.title' class='uppercase block text-danger') {{ data.promo.title }} #[span(v-if='data.promo.title && data.promo.content')]
    |
    | {{ data.promo.content }}
    |
    span(v-if='data.promo && data.promo.file' class='block') #[a(:href='getFileUrl(data.promo.file.folder, data.promo.file.filename)' target='_blank') See More].

  div(
    v-if='data.discount && !$sitewideConfig.config.nonTransactionalEnabled'
    class='relative font-bold uppercase w-full text-danger'
  )
    strong Now {{ data.discount.amountFormatted }} off
    |
    | - hurry,
    span(v-if='data.discount.expiresFormatted')
      |
      | offer ends {{ data.discount.expiresFormatted }}!
    span(v-else)
      |
      | limited time offer!

  div(v-if='data.isMadeInAmerica')
    div(class='flex items-center')
      div
        img(src='/images/us-flag.svg' alt='Made in the USA' class='max-w-none' width='40' height='37')
      div(class='relative pl-4 w-full') Made in the USA

  div(v-if='!isGroupProduct' class='relative')
    ClientOnly
      ProductVehicleSelection(
        :guaranteed-fit='$sitewideConfig.config.guaranteedFitEnabled ? data.guaranteedFit : undefined'
      )
  template(v-if='!data.isOutOfStock && !$sitewideConfig.config.nonTransactionalEnabled && (data.mpn || data.sku)')
    div(v-if='shippingText')
      div(class='flex items-center')
        div
          SameDayShippingLogo(
            v-if='$sitewideConfig.config.sameDayShippingEnabled && data.shipping.sameDay'
            class='inline-block h-auto w-8'
            is-icon
          )
          FreightBox(
            v-else-if='data.shipping.isFreight'
            class='inline-block align-middle w-10 h-auto text-action-dark stroke-current fill-current'
            style='max-height: 37px'
          )
          div(v-else class='relative rounded-full bg-success p-5 w-[32px] h-[32px] md:p-3')
            img(src='/images/checkmarkThick.svg' alt='checkmark' class='w-[20px] icon absolute m-auto inset-0')

        div(class='relative pl-4 w-full')
          p(class='font-bold text-dark') {{ shippingText }}
          //- (Nuxt 2) The other stuff that this can show doesn't matter here so
          //- we only show the shipping comp if we are sameDay
          Shipping(v-if='$sitewideConfig.config.sameDayShippingEnabled && data.shipping.sameDay' :data='data')

    div(v-if='data.shipping.estimatedDeliveryDate')
      div(class='flex items-center')
        div(class='relative rounded-full bg-success p-5 w-[32px] h-[32px] md:p-3')
          img(src='/images/checkmarkThick.svg' alt='checkmark' class='w-[20px] icon absolute m-auto inset-0')
        div(class='relative pl-4 w-full')
          Shipping(:data='data')

  LazyInstallernet(
    v-if='data.skuSlug && isIntegrationEnabled("geolocation") && isIntegrationEnabled("installernet") && data.shipping && data.shipping.sameDay'
    mode='ADD_TO_CART'
    :item='data'
  )

  div(v-if='data.mpn || data.sku' class='font-normal')
    span(class='text-left text-sm whitespace-nowrap uppercase pr-1') Part #:
    span {{ data.mpn || data.sku }}

  div(v-if='data.upc' class='font-normal')
    span(class='text-left text-sm whitespace-nowrap uppercase pr-1') UPC:
    span {{ data.upc }}

  template(v-if='isProductPage')
    template(v-for='attr in data.attributes' :key='attr.key')
      div(class='font-normal')
        span(class='text-left text-sm whitespace-nowrap uppercase pr-1') {{ attr.key }}:
        span {{ attr.value }}
</template>
<script setup lang="ts">
import FreightBox from '@/assets/freightBox.svg?component'

const { data, isGroupProduct = false } = defineProps<{ data: ProductLine | GroupProduct; isGroupProduct?: boolean }>()
const { $sitewideConfig, $uiEvents } = useNuxtApp()
const { sameDayShippingText } = useSameDayShippingDisplay()
const { isIntegrationEnabled } = useUtils()
const route = useRoute()
const isProductPage = useRoute().params.skuSlug

const shippingText = computed(() => {
  if (!data || !data.shipping) return ''
  if ($sitewideConfig.config.sameDayShippingEnabled && data.shipping.sameDay) return `${sameDayShippingText} Ready`

  return getFreeShippingText(data.shipping)
})

watch(
  () => data.shipping,
  (newVal: ProductLine['shipping'] | undefined) => {
    checkExpressShipping(newVal)
  }
)

// TODOLATER: It would seem to make more sense to put this in the Shipping component
function checkExpressShipping(shipping?: ProductLine['shipping']) {
  if (shipping?.sameDay) $uiEvents.$emit('expressShippingViewed', route.fullPath)
}
</script>
