<template lang="pug">
div(
  class='customer-photos yotpo yotpo-pictures-widget [&_.yotpo-pictures-gallery-header-wrapper]:!hidden [&_.yotpo-lightbox-product-button]:!hidden'
  :data-gallery-id='galleryId'
  :data-product-id='yotpoId'
)
</template>

<script setup lang="ts">
useYotpo()

const { yotpoId, galleryId } = defineProps<{
  yotpoId: string
  galleryId: string
}>()
</script>
