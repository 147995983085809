<template lang="pug">
div(
  class='yotpo yotpo-shoppers-say [&_.single-best-sentence_.more-reviews]:hidden lg:[&_.single-best-sentence_.more-reviews]:block'
  :data-product-id='yotpoId'
)
</template>

<script setup lang="ts">
useYotpo()
const { yotpoId } = defineProps<{ yotpoId: string }>()
</script>
